import styled from 'styled-components';
import Mooncourt from '../../icons/Mooncourt';
import BallIcon from '../../icons/BallIcon';
import { FontFamilies } from '../../../styles/FontFamilies';
import { Translations } from '../../../utils/Translations';
import { useIsApiReady } from '../../../services/ApiProvider';
import { useCallback } from 'react';
import { useResetUserState } from '../../../states/AppData';
import { BREAKPOINT_XXL } from '../../../styles/Breakpoints';
import { Button } from '../../common/Button';

const Wrapper = styled.div`
  grid-column: 1 / -1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 2rem;
  border-radius: 1.25rem;
  border: 1px solid #fff;
  background: rgba(13, 13, 13, 0.1);
  padding: 2rem 3.75rem;
  backdrop-filter: blur(6px);

  ${BREAKPOINT_XXL} {
    grid-column: 13 / 21;
  }
`;

const Header = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  svg {
    font-size: 1.5rem;
    color: var(--color-primary-750);
  }
`;

const Headline = styled.h4`
  font-family: ${FontFamilies.headline};
  text-align: center;
  font-size: 2rem;
  font-weight: 600;
  text-transform: uppercase;
`;

const BenefitsWrapper = styled.div`
  display: grid;
  gap: 1rem;
`;

const Benefit = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-family: ${FontFamilies.headline};
  font-size: 1.25rem;
  line-height: 1.25;

  svg {
    flex-shrink: 0;
    font-size: 1.5rem;
  }
`;

const LoginButton = styled(Button)`
  align-self: center;
  width: 10rem;
  justify-content: center;
`;

interface ILoginCTAProps {
  setOpenLoginModal: (value: boolean) => void;
}

export default function LoginCTA(props: ILoginCTAProps) {
  const { setOpenLoginModal } = props;
  const isApiReady = useIsApiReady();
  const resetUserState = useResetUserState();

  const handleLoginClick = useCallback(() => {
    if (isApiReady) {
      resetUserState();
    } else {
      setOpenLoginModal(true);
    }
  }, [isApiReady]);

  return (
    <Wrapper>
      <Header>
        <Mooncourt />
        <Headline>{Translations.sso.headline}</Headline>
      </Header>

      <BenefitsWrapper>
        <Benefit>
          <BallIcon /> <p>Get access to the latest sneaker releases, raffles & giveaways</p>
        </Benefit>

        <Benefit>
          <BallIcon /> <p>Collect Moonpoints to get higher chances on limited edition drops</p>
        </Benefit>

        <Benefit>
          <BallIcon /> <p>Build your team of Ballers to unlock even more rewards</p>
        </Benefit>
      </BenefitsWrapper>

      <LoginButton onClick={handleLoginClick}>{isApiReady ? 'Logout' : 'Login'}</LoginButton>
    </Wrapper>
  );
}
