import { PropsWithChildren, createContext, useEffect, useMemo } from 'react';
import styled, { css } from 'styled-components';
import { ToastContainer } from 'react-toastify';
import { useRecoilValue } from 'recoil';
import { INotifications } from '../../../types/page';
import useToast from '../../../hooks/useToast';
import TransactionState from '../../../states/TransactionState';
import { useResetUserState } from '../../../states/AppData';
import { ExternalScripts } from '../ExternalScripts';
import { CookieConsent } from '../CookieConsent';
import Header from './Header';
import 'react-toastify/dist/ReactToastify.css';
import { BREAKPOINT_MD } from '../../../styles/Breakpoints';
import { IBallerRarity } from '../../../services/rarities';
import { Translations } from '../../../utils/Translations';
import Background from './subcomponents/Background';
import { useLocation } from 'react-router-dom';
import Footer from './Footer';

const PageWrapper = styled.div<{ hasBackground?: boolean }>`
  position: relative;
  display: flex;
  flex-direction: column;
  padding-inline: var(--content-spacing);
  min-block-size: 100%;

  --toastify-toast-width: min(26rem, calc(100vw - 2rem));

  .error {
    --toastify-color-progress-light: var(--color-signal-red);
  }

  ${BREAKPOINT_MD} {
    padding-block-start: 0;
    grid-template-columns: 5.8125rem 1fr;
  }

  &::after {
    position: fixed;
    inset: 0;
    content: '';
    z-index: -1;

    ${({ hasBackground }) =>
      !hasBackground &&
      css`
        background: var(--color-black-900);
      `};
  }
`;

const StyledMain = styled.main`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: var(--content-max-width);
  flex-grow: 1;
  margin: 0 auto auto;
  align-items: start;
`;

export interface IWalletConnectTranslations {
  connectButton: string;
  connectedButton: string;
  connectHeadline: string;
  connectText: string;
  disconnectHeadline: string;
  disconnectText: string;
  disconnectButton: string;
  yourWalletAddress: string;
  close: string;
}

export const WalletConnectTranslations = createContext<IWalletConnectTranslations | undefined>(undefined);
export const Notifications = createContext<INotifications | undefined>(undefined);
export const BallerRarities = createContext<IBallerRarity[] | undefined>(undefined);

const Rarities = [
  {
    ballerClass: 'Captain',
    rarity: 'legendary',
    dailyPoints: 20,
    boost: 2,
  },
  {
    ballerClass: 'Chameleon',
    rarity: 'epic',
    dailyPoints: 16,
    boost: 1.75,
  },
  {
    ballerClass: 'Rogue',
    rarity: 'rare',
    dailyPoints: 12,
    boost: 1.5,
  },
  {
    ballerClass: 'DunknMaster',
    rarity: 'uncommon',
    dailyPoints: 8,
    boost: 1.25,
  },
  {
    ballerClass: 'Wizzard',
    rarity: 'uncommon',
    dailyPoints: 8,
    boost: 1.25,
  },
  {
    ballerClass: 'Tank',
    rarity: 'common',
    dailyPoints: 4,
    boost: 1,
  },
  {
    ballerClass: 'Whirlwind',
    rarity: 'common',
    dailyPoints: 4,
    boost: 1,
  },
  {
    ballerClass: 'Shooter',
    rarity: 'common',
    dailyPoints: 4,
    boost: 1,
  },
];

export default function Layout(props: PropsWithChildren) {
  const { children } = props;

  const { showTransactionToast } = useToast();
  const transactions = useRecoilValue(TransactionState);
  const clearCache = useResetUserState();

  const location = useLocation();
  const hasBackground = useMemo(() => {
    if (location.pathname === '/') {
      return true;
    } else if (location.pathname.includes('shop')) {
      return true;
    } else if (location.pathname.includes('training')) {
      return true;
    } else if (location.pathname.includes('inventory')) {
      return true;
    } else if (location.pathname.includes('profile')) {
      return true;
    } else if (location.pathname.includes('membership')) {
      return true;
    } else {
      return false;
    }
  }, [location]);

  useEffect(() => {
    transactions.forEach((tx) => showTransactionToast({ ...tx }));
    window.addEventListener('WALLET_DISCONNECTED', clearCache);

    return () => {
      window.removeEventListener('WALLET_DISCONNECTED', clearCache);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <PageWrapper hasBackground={hasBackground}>
      <Background />

      <Notifications.Provider value={{ ...Translations.notifications }}>
        <BallerRarities.Provider value={Rarities}>
          <WalletConnectTranslations.Provider value={{ ...Translations.walletConnect }}>
            <Header />

            <StyledMain>{children}</StyledMain>

            <Footer />

            <CookieConsent />

            <ToastContainer />
          </WalletConnectTranslations.Provider>
        </BallerRarities.Provider>
      </Notifications.Provider>
      <ExternalScripts />
    </PageWrapper>
  );
}
