import styled from 'styled-components';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import AnimatedTabSwitch from '../../common/AnimatedTabSwitch';
import { useCallback, useMemo } from 'react';

const Wrapper = styled.div`
  display: grid;
  gap: 1.25rem;
  width: 100%;
`;

const ProfileTabItems = [
  { label: 'Account', path: 'account' },
  { label: 'Address', path: 'address' },
  { label: 'Wallets', path: 'wallet' },
  { label: 'History', path: 'history' },
];

export default function Profile() {
  const location = useLocation();
  const navigate = useNavigate();

  const currentIndex = useMemo(() => {
    switch (location.pathname) {
      case '/profile/history':
        return 3;
      case '/profile/wallet':
        return 2;
      case '/profile/address':
        return 1;
      default:
        return 0;
    }
  }, [location]);

  const changeIndex = useCallback(
    (index: number) => {
      navigate(`/profile/${ProfileTabItems[index].path}`);
    },
    [navigate],
  );

  return (
    <Wrapper>
      <AnimatedTabSwitch currentIndex={currentIndex} items={ProfileTabItems} onClick={changeIndex} />
      <Outlet />
    </Wrapper>
  );
}
