import styled from 'styled-components';
import { Button } from '../../../common/Button';
import { IMembershipType } from '../index';
import { Glass } from 'src/styles/Custom';
import { useMemo } from 'react';
import { Translations } from 'src/utils/Translations';
import SubscriptionAttribute from 'src/components/content/membership/subcomponents/MembershipAttribute';
import { FontFamilies } from 'src/styles/FontFamilies';
import Tooltip, { AbsoluteWrapper } from 'src/components/common/Tooltip';
import { ILink } from 'src/types/link';
import LinkHandler from 'src/components/common/LinkHandler';

const Wrapper = styled.div`
  ${Glass};
  display: flex;
  flex-direction: column;
  padding: 2rem 1.5rem;
`;

const Header = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  margin-bottom: 1.25rem;
  gap: 0.25rem;
`;

const Name = styled.h4`
  font-size: var(--font-size-headline-md);
  font-weight: 600;
  text-transform: uppercase;
`;

const Tag = styled.span`
  display: flex;
  padding: 0.25rem 0.75rem;
  color: var(--color-signal-green);
  border: 1px solid var(--color-signal-green);
  font-size: var(--font-size-body-sm);
  border-radius: 1rem;
  margin-inline-start: auto;
`;

const Description = styled.p`
  font-size: var(--font-size-body-sm);
  margin-block-end: 1.5rem;
`;

const Body = styled.div`
  margin-block-start: auto;
`;

const PriceWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-block-end: 1rem;
`;

const Currency = styled.p`
  font-family: var(--font-family-headline);
  font-size: var(--font-size-headline-sm);
  font-weight: 600;
  margin-inline-end: 0.5rem;
`;

const Price = styled.p`
  font-family: ${FontFamilies.headline};
  font-size: var(--font-size-headline-xl);
  line-height: 1;
  font-weight: 600;
  text-transform: uppercase;
`;

const PriceLabel = styled.p`
  align-self: flex-end;
  font-size: var(--font-size-body-sm);
  margin-block-end: 0.5rem;
`;

const StyledLinkHandler = styled(LinkHandler)`
  :hover {
    text-decoration: none;
  }
`;

const ActionButton = styled(Button)`
  width: 100%;
  justify-content: center;
  margin-block-end: 2rem;
`;

const AttributesContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
`;

const Attribute = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
`;

const AttributeHead = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

const AttributeHeadline = styled.p`
  font-size: var(--font-size-body-lg);
  font-weight: 600;
`;

const AttributeTooltip = styled(Tooltip)`
  position: initial;
  font-size: 1.125rem;
  line-height: 1rem;

  ${AbsoluteWrapper} {
    width: calc(400px - 2 * 1.5rem);
    top: 2.25rem;
    left: 0;
  }
`;

const MembershipStatusTooltip = styled(AttributeTooltip)`
  margin-block-end: 0.375rem;
`;

interface IMembershipCardProps {
  type: IMembershipType;
  onClick?: () => void;
  link?: ILink;
  disabled?: boolean;
}

export default function MembershipCard(props: IMembershipCardProps) {
  const { type, onClick, link, disabled } = props;

  const translations = useMemo(() => Translations.membership.types[type], [type]);
  const benefitTranslations = useMemo(() => Translations.membership.benefits, []);

  return (
    <Wrapper>
      <Header>
        <Name>{translations.title}</Name>
        {type !== 'free' && <MembershipStatusTooltip translation={Translations.membership.types[type].tooltip} />}
        {translations.tag && <Tag>{translations.tag}</Tag>}
      </Header>

      <Description>{translations.description}</Description>

      <Body>
        <PriceWrapper>
          <Currency>€</Currency>
          <Price>{translations.payment[0]?.price}</Price>
          {translations.payment[0]?.type && <PriceLabel>/{translations.payment[0]?.type}</PriceLabel>}
        </PriceWrapper>

        {link && !disabled ? (
          <StyledLinkHandler to={link.href} title={link.title} target={link.target}>
            <ActionButton onClick={onClick} disabled={disabled}>
              {translations.buttonLabel}
            </ActionButton>
          </StyledLinkHandler>
        ) : (
          <ActionButton onClick={onClick} disabled={disabled}>
            {translations.buttonLabel}
          </ActionButton>
        )}
      </Body>

      <AttributesContainer>
        <Attribute>
          <AttributeHead>
            <AttributeHeadline>{benefitTranslations.shopAccess.label}</AttributeHeadline>
            <AttributeTooltip translation={benefitTranslations.shopAccess.tooltip} />
          </AttributeHead>

          <SubscriptionAttribute active={true}>{benefitTranslations.shopAccess.items.free}</SubscriptionAttribute>
          {/*<SubscriptionAttribute active={type === 'legacy' || type === 'subscription'}>*/}
          {/*  {benefitTranslations.shopAccess.items.subscription}*/}
          {/*</SubscriptionAttribute>*/}
          <SubscriptionAttribute active={type === 'legacy'}>{benefitTranslations.shopAccess.items.legacy}</SubscriptionAttribute>
        </Attribute>

        <Attribute>
          <AttributeHead>
            <AttributeHeadline>{benefitTranslations.includedAssets.label}</AttributeHeadline>
            <AttributeTooltip translation={benefitTranslations.includedAssets.tooltip} />
          </AttributeHead>

          <SubscriptionAttribute active={type === 'legacy' || type === 'subscription'}>
            {benefitTranslations.includedAssets.items[type]}
          </SubscriptionAttribute>
          <SubscriptionAttribute active={type === 'legacy'}>{benefitTranslations.includedAssets.items.ownership}</SubscriptionAttribute>
          <SubscriptionAttribute active={type === 'legacy'}>{benefitTranslations.includedAssets.items.nfcCard}</SubscriptionAttribute>
        </Attribute>

        <Attribute>
          <AttributeHead>
            <AttributeHeadline>{benefitTranslations.starterBonus.label}</AttributeHeadline>
            <AttributeTooltip translation={benefitTranslations.starterBonus.tooltip} />
          </AttributeHead>

          <SubscriptionAttribute active={true}>{benefitTranslations.starterBonus.items.registration}</SubscriptionAttribute>
          <SubscriptionAttribute active={type === 'legacy' || type === 'subscription'}>
            {benefitTranslations.starterBonus.items[type]}
          </SubscriptionAttribute>
        </Attribute>

        <Attribute>
          <AttributeHead>
            <AttributeHeadline>{benefitTranslations.earningMoonpoints.label}</AttributeHeadline>
            <AttributeTooltip translation={benefitTranslations.earningMoonpoints.tooltip} />
          </AttributeHead>

          <SubscriptionAttribute active={true}>{benefitTranslations.earningMoonpoints.items.free}</SubscriptionAttribute>
          <SubscriptionAttribute active={type === 'legacy' || type === 'subscription'}>
            {benefitTranslations.earningMoonpoints.items.training}
          </SubscriptionAttribute>
        </Attribute>
      </AttributesContainer>
    </Wrapper>
  );
}
