import styled from 'styled-components';
import MembershipCard from './subcomponents/MembershipCard';
import { BREAKPOINT_LG } from 'src/styles/Breakpoints';
import { useState } from 'react';
import LoginModal from 'src/components/content/user/LoginModal';
import { useIsApiReady } from 'src/services/ApiProvider';

const Wrapper = styled.div`
  align-self: center;
  display: flex;
  flex-direction: column;
  margin-block-start: 2.5rem;
`;

const Headline = styled.h2`
  text-align: center;
  font-size: var(--font-size-headline-lg);
  font-weight: 600;
  text-transform: uppercase;
  margin-block-end: 1rem;

  ${BREAKPOINT_LG} {
    margin-block-end: 1.75rem;
  }
`;

const Description = styled.p`
  text-align: center;
  font-size: var(--font-size-body-lg);
  max-width: 800px;
  align-self: center;
  margin-block-end: 2.75rem;

  ${BREAKPOINT_LG} {
    margin-block-end: 3.75rem;
  }
`;

const ItemWrapper = styled.div`
  display: grid;
  gap: 2.75rem 2rem;

  ${BREAKPOINT_LG} {
    //grid-template-columns: repeat(3, 400px);
    //max-width: calc(1200px + 4rem);
    grid-template-columns: repeat(2, 400px);
    max-width: calc(800px + 2rem);
    width: 100%;
    align-self: center;
  }
`;

export type IMembershipType = 'free' | 'subscription' | 'legacy';

export default function Membership() {
  const [openLoginModal, setOpenLoginModal] = useState(false);
  const isApiReady = useIsApiReady();

  return (
    <Wrapper>
      <Headline>Start your mooncourt journey</Headline>

      <Description>
        Choose your path to the moon: Start for free or unlock exclusive benefits with a membership. Join Mooncourt and take your sneaker
        passion to new heights.
      </Description>

      <ItemWrapper>
        <MembershipCard type={'free'} onClick={() => setOpenLoginModal(true)} disabled={isApiReady} />
        {/*<SubscriptionCard type={'subscription'} />*/}
        <MembershipCard
          type={'legacy'}
          link={{ href: 'https://43cb68-3.myshopify.com/cart/49118776361287:1?channel=buy_button', title: 'MVP Pass', target: '_blank' }}
        />
      </ItemWrapper>

      {openLoginModal && <LoginModal onClose={() => setOpenLoginModal(false)} />}
    </Wrapper>
  );
}
