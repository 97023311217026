import styled from 'styled-components';
import { useRecoilValue } from 'recoil';
import { AccountState } from '../../../states/AppData';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useUserApi } from '../../../services/user';
import { BREAKPOINT_MD } from '../../../styles/Breakpoints';
import GoogleIcon from '../../icons/GoogleIcon';
import AppleIcon from '../../icons/AppleIcon';
import EmailIcon from '../../icons/EmailIcon';
import DiscordIcon from '../../icons/DiscordIcon';
import { FontFamilies } from '../../../styles/FontFamilies';
import PencilIcon from '../../icons/PencilIcon';
import LoginModal from '../user/LoginModal';
import { useAccountApi } from '../../../services/account';
import { Glass } from '../../../styles/Custom';

const Wrapper = styled.div`
  display: flex;

  ${BREAKPOINT_MD} {
    align-items: flex-start;
  }
`;

const UserCard = styled.div`
  ${Glass};
  display: flex;
  flex-direction: column;
  padding: 1.25rem 1rem;
  gap: 0.75rem;
`;

const Mail = styled.h6`
  display: flex;
  gap: 0.5rem;
  align-items: center;
  font-family: ${FontFamilies.headline};
  font-size: 1.25rem;
  font-weight: 600;
  text-transform: uppercase;
`;

const AccountStatus = styled.h6`
  display: flex;
  gap: 0.5rem;
  align-items: center;
  font-family: ${FontFamilies.headline};
  font-size: 1.25rem;
  font-weight: 600;
  text-transform: uppercase;
`;

const ChangePassword = styled.button`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-size: 1.125rem;
  text-decoration-line: underline;
  cursor: pointer;

  svg {
    font-size: 1.25rem;
  }

  @media (hover: hover) {
    &:hover {
      color: var(--color-primary-750);
    }
  }
`;

export default function AccountTab() {
  const accountState = useRecoilValue(AccountState);
  const userApi = useUserApi();
  const accountApi = useAccountApi();
  const [openModal, setOpenModal] = useState<boolean>(false);

  useEffect(() => {
    userApi.refreshAccountState();
  }, []);

  const accountIcon = useMemo(() => {
    const accountType = accountState?.account.accountType;
    switch (accountType) {
      case 'GOOGLE':
        return <GoogleIcon />;
      case 'APPLE':
        return <AppleIcon />;
      case 'MAIL':
        return <EmailIcon />;
      case 'DISCORD':
        return <DiscordIcon />;
      default:
        return <EmailIcon />;
    }
  }, [accountState]);

  const accountStatus = useMemo(() => {
    if (accountState?.account?.level?.includes('legacy') && accountState?.account?.level?.includes('subscription')) {
      return 'Pro & Legacy';
    } else if (accountState?.account?.level?.includes('legacy')) {
      return 'Legacy';
    } else if (accountState?.account?.level?.includes('subscription')) {
      return 'Pro';
    } else {
      return 'Lite';
    }
  }, [accountState]);

  const handleChangePassword = useCallback(() => {
    if (accountState) {
      try {
        accountApi.resetPassword(accountState.account.email);
        setOpenModal(true);
      } catch (error) {
        console.log('error resetting password:', error);
      }
    }
  }, [accountState]);

  return (
    <Wrapper>
      <UserCard>
        <Mail>
          {accountIcon}
          {accountState?.account.email}
        </Mail>

        <AccountStatus>Tier: {accountStatus}</AccountStatus>

        {accountState?.account.accountType === 'MAIL' && (
          <ChangePassword onClick={handleChangePassword}>
            <PencilIcon />
            Change Password
          </ChangePassword>
        )}
      </UserCard>

      {openModal && <LoginModal initialModalType={'reset-otp'} onClose={() => setOpenModal(false)} />}
    </Wrapper>
  );
}
